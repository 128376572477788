import Exceljs from 'exceljs';
import { lensPath, set, splitAt, uniq, view } from 'ramda';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { targetHelperBackend } from './utils.js'; // const saveAs = () => {};

const id = x => x;

const supportedAtomReg = /string|number/;
const parseAtom = targetHelperBackend(type => supportedAtomReg.test(type), (_, obj) => {
  if (obj?.bindLayerSkip?.excel) return obj;
  return { ...obj,
    excel: {
      width: 1,
      height: 0,
      getColumns: (acc, {
        x,
        y,
        width,
        height,
        names,
        parentLabels
      }) => {
        const name = names[names.length - 1];
        const labels = (parentLabels || []).concat(obj.label || name);
        return acc.concat({
          x,
          y,
          i: x + width - 1,
          j: y + height - 1,
          label: obj.label || name,
          leafProps: {
            names,
            labels,
            toExcelValue: id,
            fromExcelValue: id
          }
        });
      }
    }
  };
});
const parseEnum = targetHelperBackend('enum', (_, obj) => {
  if (obj?.bindLayerSkip?.excel) return obj;
  return { ...obj,
    excel: {
      width: 1,
      height: 0,
      getColumns: (acc, {
        x,
        y,
        width,
        height,
        names,
        parentLabels
      }) => {
        const name = names[names.length - 1];
        const labels = (parentLabels || []).concat(obj.label || name);
        const valueEnum = obj.valueEnum || [];
        const value2label = valueEnum.reduce((acc, e) => ({ ...acc,
          [e.value]: e.label
        }), {});
        const label2value = valueEnum.reduce((acc, e) => ({ ...acc,
          [e.label]: e.value
        }), {});
        return acc.concat({
          x,
          y,
          i: x + width - 1,
          j: y + height - 1,
          label: obj.label || name,
          leafProps: {
            names,
            labels,
            toExcelValue: value => value2label[value],
            fromExcelValue: value => label2value[value],
            dataValidation: {
              type: 'list',
              allowBlank: !obj.required,
              formulae: [`"${valueEnum.map(e => e.label).join(',')}"`]
            }
          }
        });
      }
    }
  };
});
const parseDate = targetHelperBackend('date', (_, obj) => {
  if (obj?.bindLayerSkip?.excel) return obj;
  return { ...obj,
    excel: {
      width: 1,
      height: 0,
      getColumns: (acc, {
        x,
        y,
        width,
        height,
        names,
        parentLabels
      }) => {
        const name = names[names.length - 1];
        const labels = (parentLabels || []).concat(obj.label || name);
        return acc.concat({
          x,
          y,
          i: x + width - 1,
          j: y + height - 1,
          label: obj.label || name,
          leafProps: {
            names,
            labels,
            toExcelValue: value => moment(value).toDate(),
            fromExcelValue: value => moment(value),
            dataValidation: {
              type: 'date',
              allowBlank: !obj.required
            }
          }
        });
      }
    }
  };
});
const parseObject = targetHelperBackend('object', (_, obj) => {
  if (obj?.bindLayerSkip?.excel) return obj;
  const {
    label,
    childrenEntries: _childrenEntries
  } = obj;

  const childrenEntries = _childrenEntries.filter(([, {
    excel
  }]) => !!excel);

  const [selfWidth, _height] = childrenEntries.reduce((acc, [, {
    excel
  }]) => {
    const [currentWidth, currentHeight] = acc; // console.log(type);

    return [currentWidth + excel.width, Math.max(currentHeight, excel.height)];
  }, [0, 0]);
  const selfHeight = _height + 1;
  return { ...obj,
    excel: {
      width: selfWidth,
      height: selfHeight,
      getColumns: (acc, {
        x,
        y,
        width,
        height,
        names,
        parentLabels
      }, isRoot = false) => {
        const lastName = !isRoot && names[names.length - 1];
        return acc.concat(!isRoot ? [{
          x,
          y,
          i: x + width - 1,
          j: y + height - 1,
          label: label || lastName
        }] : [], childrenEntries.reduce(([stagedWidth, acc], [name, {
          excel
        }]) => {
          const {
            width: childWidth,
            height: childHeight,
            getColumns
          } = excel;
          return [stagedWidth + childWidth, getColumns(acc, {
            x: x + stagedWidth,
            y: isRoot ? y : y + height,
            width: childWidth,
            height: selfHeight - childHeight,
            names: names.concat(name),
            parentLabels: isRoot ? parentLabels : parentLabels.concat(label || lastName)
          })];
        }, [0, []])[1]);
      }
    }
  };
});
export default {
  parseAtom,
  parseObject,
  parseDate,
  parseEnum
};
export const writeWorkBook = (columns, datas, height, sheetName = 'sheet1') => {
  const wb = new Exceljs.Workbook();
  const ws = wb.addWorksheet(sheetName);
  writeHeader(ws, ...columns);
  const leafColumns = columns.filter(({
    leafProps
  }) => !!leafProps);
  const toExcellValue = createToExcelValue(...leafColumns);
  ws.addRows(datas.map(toExcellValue));
  leafColumns.forEach(({
    x,
    leafProps: {
      dataValidation
    }
  }) => {
    if (!dataValidation) return;
    ws.getColumn(x + 1).eachCell((cell, rowNumber) => {
      if (rowNumber < height + 1) return;
      cell.dataValidation = dataValidation;
    });
  });
  return wb;
};
export const readWorkBook = (columns, wb, height, sheetName = 'sheet1') => {
  const ws = wb.getWorksheet(sheetName);
  const rows = [];
  ws.eachRow(row => {
    rows.push(row.values);
  });
  const [heads, datas] = splitAt(height, rows);
  const leafColumns = columns.filter(({
    leafProps
  }) => !!leafProps);
  const fromExcellValue = createFromExcelValue(...leafColumns);
  return datas.map(data => {
    const tmp = data.reduce((acc, v, idx) => {
      const labels = heads.map(h => h[idx]); // if (labels.any(l => !l)) return acc;

      return acc.concat([[uniq(labels), v]]);
    }, []);
    return fromExcellValue(...tmp);
  });
};
export const read = async file => {
  const buffer = await new Promise(res => {
    const reader = new FileReader();

    reader.onload = function (e) {
      res(new Uint8Array(e.target.result));
    };

    reader.readAsArrayBuffer(file);
  });
  const wb = new Exceljs.Workbook();
  await wb.xlsx.load(buffer);
  return wb;
};
export const write = async (wb, filename = '数据') => {
  const buffer = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buffer], {
    type: 'application/octet-stream'
  }), filename.endsWith('.xlsx') ? filename : `${filename}.xlsx`);
}; // helpers

export const createFromExcelValue = (..._leafColumns) => {
  const leafColumnsMap = _leafColumns.reduce((acc, {
    leafProps: {
      labels,
      names,
      fromExcelValue
    }
  }) => ({ ...acc,
    [labels.join('-')]: {
      names,
      fromExcelValue
    }
  }), {});

  return (...columns) => columns.reduce((acc, [labels, value]) => {
    const {
      names,
      fromExcelValue
    } = leafColumnsMap[labels.join('-')];
    return set(lensPath(names), fromExcelValue(value), acc);
  }, {});
};
export const createToExcelValue = (..._leafColumns) => {
  const leafColumns = _leafColumns.sort(({
    x: lhs
  }, {
    x: rhs
  }) => lhs < rhs);

  return obj => leafColumns.map(({
    leafProps: {
      names,
      toExcelValue
    }
  }) => toExcelValue(view(lensPath(names), obj)));
};
export const toExcelRowIdx = v => v + 1;
export const toExcelColIdx = v => v < 26 ? String.fromCharCode(65 + v) : `A${String.fromCharCode(65 + v - 26)}`;
export const writeHeader = (ws, ...columns) => {
  columns.forEach(({
    x,
    y,
    i,
    j,
    label
  }) => {
    if (x !== i || y !== j) {
      try {
        const tmp = `${toExcelColIdx(x)}${toExcelRowIdx(y)}:${toExcelColIdx(i)}${toExcelRowIdx(j)}`;
        console.log('merge: ', tmp);
        ws.mergeCells(tmp);
      } catch (e) {
        console.log(columns);
        console.error(e);
        throw e;
      }
    }

    ws.getCell(`${toExcelColIdx(x)}${toExcelRowIdx(y)}`).value = label;
  });
};