import { targetHelper } from './utils';
const parseObject = targetHelper('object', ([next], v) => {
  const {
    initialValue,
    ...others
  } = next(v);

  const childrenInitialValue = () => others.childrenEntries.reduce((acc, [name, {
    initialValue
  }]) => ({ ...acc,
    [name]: initialValue
  }), {});

  return { ...others,
    initialValue: initialValue || childrenInitialValue()
  };
});
const parseGroup = targetHelper('group', ([next], v) => {
  const {
    initialValue,
    ...others
  } = next(v);

  const childrenInitialValue = () => others.groupUnits.reduce((acc, {
    initialValue
  }) => ({ ...acc,
    ...initialValue
  }), {});

  return { ...others,
    initialValue: initialValue || childrenInitialValue()
  };
});
const parseArray = targetHelper('array', ([next], v) => {
  const {
    initialValue,
    ...others
  } = next(v);
  return { ...others,
    initialValue: initialValue || Array.from({
      length: others.min || 1
    }).map(() => others.childProps.initialValue)
  };
});
const parseEnumGroup = targetHelper('enumGroup', ([next], v) => {
  const {
    initialValue,
    ...others
  } = next(v);
  return { ...others,
    initialValue: initialValue || [others.enumType.initialValue]
  };
});
const parseDependency = targetHelper('dependency', ([next], v) => {
  const {
    initialValue,
    ...others
  } = next(v);
  return { ...others,
    initialValue: initialValue || others.dependency.initialValue
  };
});
export default {
  parseObject,
  parseArray,
  parseGroup,
  parseEnumGroup,
  parseDependency
};