// import { omit } from 'ramda';
export const ZJ_FORM_STORE = {};
/**
 * 复杂的一个FORM—CONFIG
 * @returns object
 */

export const useFormStore = (store = ZJ_FORM_STORE) => {
  return new Proxy(store, {
    get(target, key) {
      if (key === 'register') {
        return (key, value) => {
          // console.log(
          //   `%c [ 开始 注册 ${key} ]`,
          //   'font-size:13px; background:#229f85; color:#fff;',
          // );
          //注册不允许原值存在
          if (!key || target[key]) {
            warningKeyDuplicate(key);
            return value;
          } // 先处理嵌套方案


          if (key.includes('.')) {
            //走一遍，防止报错，顺便建立路径数据（每一层都建立空对象），直至最后一步
            const valueOrNull = safeGetOrSetDefaultValue(target, key, value); // 看值存不存在，存在就返回，不相等就报警

            if (valueOrNull !== value) {
              warningKeyDuplicate(key);
            }

            return valueOrNull;
          } // 再处理普通情况


          target[key] = value; // target.set(key, value);

          process.env.debug && console.log('%c [ ZJ_FORM_STORE[key] ]', 'font-size:13px; background:pink; color:#bf2c9f;', target[key]);
          return value;
        };
      }

      if (key.includes('.')) {
        return safeGet(target, key);
      }

      return target[key]; // target.get(key);
    },

    set(target, k, v) {
      target[k] = v; // target.set(k, v);
    }

  });
};

const generatorProxy = input => {
  return new Proxy(input, {
    get(target, key) {
      if (key === 'get') {
        return () => {
          if (typeof target === 'object' && target.__empty) {
            return undefined;
          }

          delete target.__empty;

          if (typeof input !== 'object') {
            return input;
          }

          return target;
        };
      }

      return typeof target[key] === 'object' || typeof target[key] === 'undefined' ? generatorProxy(target[key] ? Object.assign(target[key], {
        __empty: false
      }) : {
        __empty: true
      }) : generatorProxy(target[key]); //此处非对象类型没有被包裹，会导致下一层继续跑的时候出错。
    }

  });
};

export const useFormSelector = (fn = state => state, _store = ZJ_FORM_STORE) => {
  const store = generatorProxy(_store);
  process.env.debug && console.log('%c [ store ]', 'font-size:13px; background:pink; color:#bf2c9f;', store);

  try {
    return (typeof fn(store).get === 'function' ? fn(store).get : () => fn(store))();
  } catch (e) {
    console.error('访问的属性值为非对象类型的', e);
    return undefined;
  }
};

const warningKeyDuplicate = key => console.log(`%c [ 操作取消 ，key ${key} 已经存在或为falsy值 , 请不要重复register同一个key，如果有必要， 建议命名为 permission.name 用于区分  ]`, 'font-size:13px; background:white; color:red;');

function safeGet(target, keyChain) {
  return safeGetOrSetDefaultValue(target, keyChain, null);
}

function safeGetOrSetDefaultValue(target, keyChain, value) {
  if (!keyChain.length) {
    throw new Error('keyChain 必须为有效长度');
  }

  const arr = keyChain.split('.');
  let next = target;
  arr.forEach((item, index) => {
    if (!next[item]) {
      if (arr.length - 1 === index) {
        next[item] = value;
      } else next[item] = {};
    }

    next = next[item];
  });
  return next;
} // const data = { a: { b: { c: { d: { e: 3 }, f: 4 }, g: 5 }, h: 6 } };
// console.log(safeGet(data, 'a.b'));
// console.log(safeGet(data, 'a.b.c.d.e'));
// console.log(safeGet(data, 'a.h'));
// console.log(safeGet(data, 'a.k.j'));
// console.log('[ data ] >', data);