const i18n = {
  'en-US': {
    'login.form.title': '登陆系统',
    'login.form.userName.errMsg': 'Username cannot be empty',
    'login.form.password.errMsg': 'Password cannot be empty',
    'login.form.login.errMsg': 'Login error, refresh and try again',
    'login.form.userName.placeholder': 'Username ',
    'login.form.password.placeholder': 'Password',
    'login.form.rememberPassword': 'Remember password',
    'login.form.forgetPassword': 'Forgot password',
    'login.form.login': 'login',
    'login.form.register': 'register account',
    'login.banner.slogan1': 'Out-of-the-box high-quality template',
    'login.banner.subSlogan1': 'Rich page templates, covering most typical business scenarios',
    'login.banner.slogan2': 'Built-in solutions to common problems',
    'login.banner.subSlogan2': 'Internationalization, routing configuration, state management everything',
    'login.banner.slogan3': 'Access visualization enhancement tool AUX',
    'login.banner.subSlogan3': 'Realize flexible block development'
  },
  'zh-CN': {
    'login.form.title': '登录语音外呼系统',
    'login.form.userName.errMsg': '用户名不能为空',
    'login.form.password.errMsg': '密码不能为空',
    'login.form.login.errMsg': '登录出错，请重试',
    'login.form.userName.placeholder': '用户名',
    'login.form.password.placeholder': '密码',
    'login.form.rememberPassword': '记住密码',
    'login.form.forgetPassword': '忘记密码',
    'login.form.login': '登录',
    'login.form.register': '注册账号',
    'login.banner.slogan1': '操作简单、快捷',
    'login.banner.subSlogan1': '一键导入人员信息，同时最大支持1000路电话，快速发布关键信息',
    'login.banner.slogan2': '',
    'login.banner.subSlogan2': '',
    'login.banner.slogan3': '',
    'login.banner.subSlogan3': ''
  }
};
export default i18n;