export const Website = 'http://alert.zha-ji.cn';
export const API_SERVER_HOST = process.env.NODE_ENV !== 'production' ? 'http://localhost:8080' //'http://8af9-39-183-21-203.ngrok.io'
: // 'http://192.168.31.84:8080' //'http://8af9-39-183-21-203.ngrok.io'
'http://alert.zha-ji.cn';
export const TENCENT_CAPTCHA = 'https://ssl.captcha.qq.com/TCaptcha.js'; //腾讯验证码的加载区域

export const TENCENT_CAPTCHA_APP_ID = '2060477455';
export const APP_NAME = '语音智能外呼平台';
export const TENCENT_COS_REGION = 'ap-shanghai';
export const TENCENT_COS_BUCKET = 'zhaji-1255393412'; //需要和getCosToken后台一致

export const TENCENT_COS_PREFIX = `${TENCENT_COS_BUCKET}.cos.${TENCENT_COS_REGION}.myqcloud.com/`;
export const AMAP_WEB_KEY = '43195694b0b9d673409910e60cd73b4a'; //用来保持登陆的，不要动，为了调试方便吧

let AuthorizationToken = null; // Authorization Token，登陆的凭证

export const getAuthorizationToken = () => AuthorizationToken;
export const setAuthorizationToken = t => AuthorizationToken = t;