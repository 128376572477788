/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { generatePermission } from '@/routes';
import { reInjectAuthorization } from '@/service/request';
import createStore from 'zustand';
import { persist } from 'zustand/middleware';
import defaultSettings from '../settings.json';
const initialState = {
  settings: defaultSettings,
  auth: {},
  userInfo: {
    permissions: {}
  }
};
export const useRootStore = createStore(persist((set, _get) => ({
  settings: defaultSettings,
  auth: {},
  userInfo: {
    permissions: {}
  },
  token: null,
  setUserInfo: (user, token) => {
    reInjectAuthorization(token);
    set({
      userInfo: { ...user,
        permissions: generatePermission(user.id === 1 ? 'admin' : 'normal')
      },
      token
    });
  }
}), {
  name: '_rootNeyio',
  onRehydrateStorage: state => {
    console.log('hydration starts'); // optional

    return (state, error) => {
      if (error) {
        console.log('an error happened during hydration', error);
      } else {
        // 重新注入Headers Authorization
        reInjectAuthorization(state.token);
        console.log('hydration finished');
      }
    };
  }
}));
export default function store(state = initialState, action) {
  switch (action.type) {
    case 'update-settings':
      {
        const {
          settings
        } = action.payload;
        return { ...state,
          settings
        };
      }

    case 'update-userInfo':
      {
        const {
          userInfo = initialState.userInfo,
          userLoading
        } = action.payload;
        return { ...state,
          userLoading,
          userInfo
        };
      }

    default:
      return state;
  }
}