export const targetHelper = (type, handler) => (action, v) => {
  const is = typeof type === 'function' ? type(v.type) : v.type === type;
  if (is) return handler(action, v);
  return action[0](v);
};

const throwError = () => {
  throw new Error('[targetHelperBackend]为后项处理器,不提供next&reParse功能，如果要使用,请选择[targetHelper]');
};

export const targetHelperBackend = (type, handler) => (action, _v) => {
  const v = action[0](_v);
  const is = typeof type === 'function' ? type(v.type) : v.type === type;
  if (is) return handler([throwError, throwError], v);
  return v;
};