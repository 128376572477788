import { targetHelper } from './utils';
const parseObject = targetHelper('object', ([next], v) => {
  const obj = next(v); // const dataIndex = obj.dataIndex;|| dataIndex ||

  return { ...obj,
    getTableColumns: (name, isHidden = false) => {
      if (isHidden || obj?.bindLayerSkip?.tableColumn) return [];
      const names = name != null ? Array.isArray(name) ? name : [name] : [];
      const tableColumns = obj.childrenEntries.map(([n, {
        getTableColumns
      }]) => getTableColumns(names.concat(n)));
      return [].concat(...tableColumns);
    }
  };
});
const parseGroup = targetHelper('group', ([next], v) => {
  const obj = next(v);
  return { ...obj,
    getTableColumns: (name, isHidden = false) => {
      if (isHidden || obj?.bindLayerSkip?.tableColumn) return [];
      const names = name != null ? Array.isArray(name) ? name : [name] : [];
      const tmps = [].concat(...obj.groupUnits.map(({
        getTableColumns
      }) => getTableColumns));
      const tableColumns = tmps.map(f => f(names));
      return [].concat(...tableColumns);
    }
  };
});
const parseAtom = targetHelper(v => !/(object)|(group)|(depend)|(enum)/.test(v), ([next], v) => {
  const obj = next(v);
  console.log('%c [ obj ]', 'font-size:13px; background:pink; color:#bf2c9f;', obj);
  const dataIndex = obj.dataIndex;

  if (dataIndex) {
    console.error('%c [ dataIndex ]', 'font-size:13px; background:pink; color:#bf2c9f;', dataIndex);
  }

  return { ...obj,
    getTableColumns: name => {
      if (!obj.tableShow || !obj.tableColumnsProps || obj?.bindLayerSkip?.tableColumn) return [];
      return [{
        title: obj.label,
        dataIndex: dataIndex || name,
        ...obj.tableColumnsProps,
        ...(dataIndex ? {
          dataIndex
        } : {})
      }];
    }
  };
});
const parseEnum = targetHelper('enum', ([next], v) => {
  const obj = next(v);
  return { ...obj,
    getTableColumns: name => {
      if (!obj.tableShow || !obj.tableColumnsProps || obj?.bindLayerSkip?.tableColumn) return [];
      const valueEnum = Array.isArray(obj.valueEnum) && obj.valueEnum.reduce((acc, {
        label,
        value
      }) => ({ ...acc,
        [value]: {
          text: label
        }
      }), {});
      return [{
        title: obj.label,
        dataIndex: name,
        ...obj.tableColumnsProps,
        ...(valueEnum && {
          valueEnum
        })
      }];
    }
  };
}); // const parseDependency = targetHelper('dependency', ([next], v) => {
//   const obj = next(v);
//   return {
//     ...obj,
//     getTableColumns: obj.dependency.getTableColumns,
//   };
// });

export default {
  parseGroup,
  parseObject,
  parseAtom,
  parseEnum // parseDependency,

};