import { useFormStore, ZJ_FORM_STORE } from '../../../forms/config';
const valueSymbol = Symbol('value');
const typeSymbol = Symbol('constLazyValue');
const mergeLeftReg = /_ml$/;
const mergeRightReg = /_mr$/;
const arrayConcatLeftReg = /_al$/;
const arrayConcatRightReg = /_ar$/;
const functionReg = /_f$/;

const compose = (...funcs) => funcs.reduce((acc, func) => (...args) => acc(func(...args)));

const convertArgs = createValueFn => (...args) => {
  if (args.length > 1) {
    if (args.some(fn => typeof fn !== 'function')) throw new Error('[modifier]多参数版本仅仅支持函数参数');
    return args;
  }

  if (args.length === 0) throw new Error('[modifier]没有入参');
  const arg = args[0]; // return typeof arg === 'function' ? args : [createValueFn(arg)];

  return [createValueFn(arg)];
};

const cachePassthrough = toValueFns => {
  const ret = Object.create(proxyBase);
  ret[valueSymbol] = toValueFns;
  return ret;
};

const baseFunctionModifier = (toValueFns, propName, fn, replace = false) => {
  const ret = Object.create(proxyBase);
  ret[valueSymbol] = toValueFns.concat({
    propName,
    replace,

    func(prev) {
      return { ...prev,
        [propName]: fn(prev?.[propName])
      };
    }

  }); // ret[typeSymbol] = true;

  return ret;
};

const id = x => x;

const functionModifier = (toValueFns, propName, ...args) => {
  const funcs = convertArgs(id)(...args);
  const bundleFn = compose(...funcs.reverse());
  return baseFunctionModifier(toValueFns, propName, bundleFn);
};

const Const = x => () => x;

const replaceModifier = (toValueFns, propName, ...args) => {
  if (args.length > 1) throw new Error('[replaceModifier]仅仅支持单参数版本');
  const funcs = convertArgs(Const)(...args);
  const bundleFn = funcs[0];
  return baseFunctionModifier(toValueFns, propName, bundleFn, true);
};

const mergeLeftModifer = (toValueFns, propName, ...args) => {
  const funcs = convertArgs(Const)(...args); // const vs = funcs.map((f) => f());
  // console.log('mergeLeft', vs);

  const bundleFn = v => Object.assign({}, v, ...funcs.map(f => f()));

  return baseFunctionModifier(toValueFns, propName, bundleFn);
};

const mergeRightModifer = (toValueFns, propName, ...args) => {
  const funcs = convertArgs(Const)(...args);

  const bundleFn = v => Object.assign({}, ...funcs.map(f => f()).concat(v));

  return baseFunctionModifier(toValueFns, propName, bundleFn);
};

const arrayConcatLeftModifier = (toValueFns, propName, ...args) => {
  const funcs = convertArgs(Const)(...args);

  const bundleFn = v => [].concat(v, funcs.map(f => f()));

  return baseFunctionModifier(toValueFns, propName, bundleFn);
};

const arrayConcatRightModifier = (toValueFns, propName, ...args) => {
  const funcs = convertArgs(Const)(...args);

  const bundleFn = v => [].concat(funcs.map(f => f()), v);

  return baseFunctionModifier(toValueFns, propName, bundleFn);
};

const proxyBase = new Proxy({}, {
  get(_, propName, receiver) {
    if (propName === '__value') return getValue(receiver);
    if (propName === typeSymbol) return true;
    if (mergeLeftReg.test(propName)) return (...args) => mergeLeftModifer(receiver[valueSymbol], propName.substr(0, propName.length - 3), ...args);
    if (mergeRightReg.test(propName)) return (...args) => mergeRightModifer(receiver[valueSymbol], propName.substr(0, propName.length - 3), ...args);
    if (arrayConcatLeftReg.test(propName)) return (...args) => arrayConcatLeftModifier(receiver[valueSymbol], propName.substr(0, propName.length - 3), ...args);
    if (arrayConcatRightReg.test(propName)) return (...args) => arrayConcatRightModifier(receiver[valueSymbol], propName.substr(0, propName.length - 3), ...args);
    if (functionReg.test(propName)) return (...args) => functionModifier(receiver[valueSymbol], propName.substr(0, propName.length - 2), ...args);

    if (propName === 'register') {
      return (key, store = ZJ_FORM_STORE) => {
        return useFormStore(store).register(key, cachePassthrough(receiver[valueSymbol]));
      };
    }

    return (...args) => replaceModifier(receiver[valueSymbol], propName, ...args);
  }

});
const cache = new WeakMap();
export const getValue = v => {
  if (cache.has(v)) return cache.get(v);
  const valueFs = v[valueSymbol];
  const [computeFns] = valueFs.reverse().reduce(([acc, set], {
    replace,
    propName,
    func
  }) => {
    if (set.has(propName)) return [acc, set];

    if (replace) {
      set.add(propName);
    }

    return [acc.concat(func), set];
  }, [[], new Set()]);
  const value = compose(...computeFns)({});
  cache.set(v, value);
  return value;
};
export const V = Object.create(proxyBase);
V[valueSymbol] = [];
export const isConstLazyValue = v => !!v[typeSymbol];