import { group, object, parseWithEnv } from '../lib/Form/CommonFormItem'; // import { getValue } from '../lib/utils/constLazyValue/core';

import { useFormStore } from './config';
export const makeForm = (mode = 'create') => (columns = []) => {
  const store = useFormStore();
  return parseWithEnv({
    mode
  })(group(...columns.map(column => {
    // object层
    return object(column.reduce((pre, item) => {
      let mergeObj = {};
      let putIn = null;

      if (typeof item === 'string') {
        putIn = store[item];
        mergeObj[item] = putIn;
      }

      if (typeof item === 'object') {
        const {
          key,
          ext,
          commonItem,
          ...rest
        } = item;

        if (!key) {
          console.log('%c [ key 不存在！！！！,直接返回原值,可能存在bug请留意]', 'font-size:13px; background:#000; color:#fff;');
          return item; // throw new Error('key必须存在');//
        }

        const actor = store[ext || key];

        if (!commonItem && !actor) {
          throw new Error('请确保该模式下必须具备ext||key或传入object');
        }

        const doRec = Object.keys(rest).reduce((acc, i) => {
          return acc[i](rest[i]);
        }, ext ? store[ext || key] : commonItem); // const da = getValue(doRec);
        // console.log(
        //   '%c [ da ]',
        //   'font-size:13px; background:pink; color:#bf2c9f;',
        //   key,
        //   da,
        //   rest,
        // );

        return {
          [key]: doRec
        };
      }

      return { ...pre,
        ...mergeObj
      };
    }, {}));
  })));
};
/**
 * 生成配置
 * @param {Array} arr
 * @returns Array<any>
 */

export const genColumns = arr => {
  console.log('%c [ arr ]', 'font-size:13px; background:pink; color:#bf2c9f;', arr);

  if (!arr) {
    throw new Error('错误的配置数组');
  }

  return arr.map(i => {
    return Array.isArray(i) ? i : [i];
  });
};
/**
 * 构建札记的套路
 * @param {{create?:string|{key,ext,[key:string]?:any},update?:string|{key,ext,[key:string]?:any},table?:string|{key,ext,[key:string]?:any}}} mapping
 * @param {Array<string>} modes
 * @returns any
 */

export const makeZhajiObject = (mapping = {}, //{ create: [ "id","title","content","cover",[{ key:'he',ext:"xx",label:"",...others }]"website","brief","files","created_at","updated_at"]};
modes = ['create', 'update', 'table']) => {
  const isExsistedInModesAndUseAnyExsisted = mode => {
    const r = mapping[mode] ? mode : modes.find(m => mapping[m]);

    if (r) {
      console.log('%c [ r ]', 'font-size:13px; background:pink; color:#bf2c9f;', r);
      return r;
    }

    console.log('[ mode ] >', mode);
    throw new Error('No Any Mode In Configuration');
  };

  const gColumns = mode => {
    return genColumns(mapping[isExsistedInModesAndUseAnyExsisted(mode)]);
  };

  const zhajiObject = modes.reduce((pre, mode) => {
    if (mode === 'table') {
      return { ...pre,
        table: makeForm(mode === 'table' ? 'create' : mode)(gColumns(mode)).getTableColumns()
      };
    }

    return { ...pre,
      [mode]: makeForm(mode === 'table' ? 'create' : mode)(gColumns(mode))
    };
  }, {});
  return zhajiObject;
};