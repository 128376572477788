/* eslint-disable @typescript-eslint/no-explicit-any */
import auth from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
import lazyload from './utils/lazyload';
export const routes = [{
  name: 'menu.voice',
  key: 'voice',
  children: [{
    name: 'menu.voice.list',
    key: 'voice/list'
  }, {
    name: 'menu.voice.create',
    key: 'voice/create'
  }, {
    name: 'menu.voice.task.id',
    key: 'voice/task/:id/',
    componentPath: 'voice/task/id',
    breadcrumb: true,
    hide: true
  }]
}, {
  name: 'menu.rbac',
  key: 'rbac',
  requiredPermissions: [{
    resource: 'menu.rbac.users',
    actions: ['write']
  }],
  children: [{
    name: 'menu.rbac.users.id.edit',
    key: 'rbac/users/:id/edit',
    componentPath: "rbac/users/id/edit",
    breadcrumb: true,
    hide: true
  }, {
    name: 'menu.rbac.users.create',
    key: 'rbac/users/create',
    componentPath: "rbac/users/create",
    breadcrumb: true
  }, {
    name: 'menu.rbac.users',
    key: 'rbac/users',
    componentPath: "rbac/users/index",
    breadcrumb: true
  }]
}, {
  name: 'menu.rbac.company',
  key: 'rbac.company',
  requiredPermissions: [{
    resource: 'menu.rbac.company',
    actions: ['write']
  }],
  children: [{
    name: 'menu.rbac.company.id.edit',
    key: 'rbac/company/:id/edit',
    componentPath: "rbac/company/id/edit",
    breadcrumb: true,
    hide: true
  }, {
    name: 'menu.rbac.company.create',
    key: 'rbac/company/create',
    componentPath: "rbac/company/create",
    breadcrumb: true
  }, {
    name: 'menu.rbac.company',
    key: 'rbac/company',
    componentPath: "rbac/company/index",
    breadcrumb: true
  }]
} // {
//   name: 'menu.dashboard',
//   key: 'dashboard',
//   children: [
//     {
//       name: 'menu.dashboard.charts',
//       key: 'dashboard/charts',
//     },
//     {
//       name: 'menu.dashboard.workplace',
//       key: 'dashboard/workplace',
//     },
//     {
//       name: 'menu.dashboard.monitor',
//       key: 'dashboard/monitor',
//       requiredPermissions: [
//         { resource: 'menu.dashboard.monitor', actions: ['write'] },
//       ],
//     },
//   ],
// }
];
export const privateRoutes = [{
  key: 'sites/create',
  component: lazyload(() => import('@/pages/sites/create'))
}];
export const getName = (path, routes) => {
  return routes.find(item => {
    const itemPath = `/${item.key}`;

    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};
export const generatePermission = role => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach(item => {
    if (item.children) {
      item.children.forEach(child => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};

const useRoute = userPermission => {
  const filterRoute = (routes, arr = []) => {
    if (!routes.length) {
      return [];
    }

    for (const route of routes) {
      const {
        requiredPermissions,
        oneOfPerm
      } = route;
      let visible = true;

      if (requiredPermissions) {
        visible = auth({
          requiredPermissions,
          oneOfPerm
        }, userPermission);
      }

      if (!visible) {
        continue;
      }

      if (route.children && route.children.length) {
        const newRoute = { ...route,
          children: []
        };
        filterRoute(route.children, newRoute.children);

        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route
        });
      }
    }

    return arr;
  };

  const [permissionRoute, setPermissionRoute] = useState(routes);
  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission]);
  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];

    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }

    return '';
  }, [permissionRoute]);
  return [permissionRoute, defaultRoute];
};

export default useRoute;