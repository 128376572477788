import { id } from '../functional';
import { isConstLazyValue, getValue } from './core';
/**
 * @example
 * const addMessageParser = ([next, _reParse], o) => {
 *   return next({ ...o, message: 'hello world' });
 * };
 *
 * const objectParser = ([next, reParse], obj) => {
 *   if (obj.type === 'object') {
 *     const { children, type: _type, ...others } = obj;
 *     const childrenEntries = Object.entries(children).map(([name, v]) => [name, reParse(v)]);
 *     const v = next(others);
 *     return { ...v, childrenEntries };
 *   }
 *   return next(obj);
 * };
 *
 * const atomParser = (_, o) => {
 *   return { ...o, hhh: 'hhh' };
 * };
 *
 * const p = createParser(addMessageParser, objectParser, atomParser);
 *
 * p({
 *   type: 'object',
 *   children: {
 *     a: {
 *       type: 'object',
 *       children: {
 *         aa: {
 *           value: 'aa',
 *           type: 'string',
 *         },
 *       },
 *     },
 *     b: {
 *       type: 'number',
 *       value: 123,
 *     },
 *   },
 * });
 *
 * @param  {...any} parsers  todo
 * @returns {function} todo;
 */

export const createParser = (...parsers) => {
  const [reParse, sertParse] = (() => {
    let instance = null;
    return [function (...args) {
      if (instance) return instance(...args);
    }, v => instance = v];
  })();

  const p = parsers.reduce((acc, parser) => next => acc((...args) => parser([next, reParse], ...args)), n => n)(id);
  sertParse(p);
  return p;
};
export const bundleParser = (o, k = 'type') => {
  const entries = Object.entries(o);
  return ([next], v) => {
    const value = next(v);
    if (!value || !value[k]) return value;
    return entries.reduce((acc, [key, parser]) => ({ ...acc,
      [key]: parser(value, x => x[key])
    }), {});
  };
};
export const tryGetValue = v => isConstLazyValue(v) ? getValue(v) : v;