import "@arco-design/web-react/es/Message/style";
import _Message from "@arco-design/web-react/es/Message";
import { TENCENT_COS_PREFIX } from '../env';
export const transformCosKey = url => {
  return url.replace(TENCENT_COS_PREFIX, '');
};
const STYLES = {
  education: 'education',
  cover: '?imageMogr2/thumbnail/414x256!/format/jpg/interlace/1/quality/95',
  //'cover',
  train: 'train',
  post: 'post',
  article: 'article',
  swiper: '?imageMogr2/thumbnail/1234×463!/format/jpg/interlace/1/quality/95'
};
export const fileSrcMixer = url => {
  if (!url) {
    return '#';
  }

  let _url = url;

  try {
    if (typeof url === 'object' && url) {
      _url = url.url;
    }

    if (!_url.startsWith('http')) {
      _url = 'https://' + _url;
    }

    return _url;
  } catch (e) {
    console.error(url);
    console.error(e);
    return url;
  }
};
export const imageSrcMixer = (url, style) => {
  if (!url) {
    return 'https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3959&q=80';
  }

  let _url = url;

  try {
    if (typeof url === 'object' && url) {
      _url = url.url;
    }

    if (!_url.startsWith('http')) {
      _url = 'https://' + _url;
    }

    _url = style ? `${_url}${STYLES[style] ? '?' + STYLES[style] : ''}` : _url;
    return _url;
  } catch (e) {
    console.error(url);
    console.error(e);
    return url;
  }
};
export const imageSrcMixer2 = (url, style) => {
  if (!url) {
    return '/images/failed.svg';
  }

  let _url = url;

  try {
    if (typeof url === 'object' && url) {
      _url = url.url;
    }

    if (!_url.startsWith('http')) {
      _url = 'https://' + _url;
    }

    _url = style ? `${_url}${STYLES[style] ? '!' + STYLES[style] : ''}` : _url;
    return _url;
  } catch (e) {
    console.error(url);
    console.error(e);
    return url;
  }
};
/**
 * Callback 模式 转换为 Promise
 * @param {function} fn
 * @param  {...any} args 入参
 * @returns {Promise} 返回值
 */

export const callbackToPromise = (fn, ...args) => {
  return new Promise((res, rej) => {
    const callback = (err, ...others) => {
      if (err) {
        return rej(err);
      } else {
        return res(...others);
      }
    };

    fn(...args, callback);
  });
}; // f(a,callback);

const chineseText = ['', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
/**
 * 转化整数
 * @param {*} integer 转化整数
 * @return {string} 中文
 */

export const covertIntegerToText = integer => {
  let acc = `${integer}`;
  chineseText.forEach((txt, num) => {
    acc = acc.replace(num, txt);
  });
  return acc;
};
export const waitTime = (time = 1000) => {
  return new Promise(res => {
    setTimeout(() => {
      res(true);
    }, time);
  });
};
export const optionalFns = async (fn, ...fns) => {
  try {
    const data = await fn();
    return fns.reduce(async (preAns, nextFn) => {
      return await nextFn(preAns);
    }, data); //  return data;
  } catch (e) {
    console.error(e);

    _Message.error('执行过程出错，请联系管理员进行排查');

    return false;
  }
};
export const conditionFn = async (ans, fn = async a => {
  return a;
}, ...args) => {
  if (ans) {
    return await fn(...args);
  }

  return false;
};