import "@arco-design/web-react/es/Message/style";
import _Message from "@arco-design/web-react/es/Message";
import { responseHelper } from '../helpers/response';
import { request } from './request';
/**
 * 请求登陆
 * @param {*} username
 * @param {*} password
 * @returns 返回 token 和 user
 */

export const doLogin = async (username, password) => {
  return request.post('/login', {
    username,
    password
  }, {
    withCredentials: false
  });
};
export const doFetchProfile = async () => {
  return responseHelper(await request.get('/api/my/profile')).data;
};
/**
 * 获取或的菜单
 * @returns [Array<number>] 返回 menu的Ids
 */

export const fetchMyRoleMenu = async () => {
  const data = await request('/api/my/roleMenu');
  return responseHelper(data).data;
};
/**
 * 获取上传的token，需要 指定type类型，如果不指定默认使用用户自身的路径，
 * @param {"user"|"company"|"cunan"} type
 * @returns {{expiredTime,expiration,credentials:{sessionToken,},requestId,startTime} }
 */

export const fetchCosSignToken = async (type = 'cunan') => {
  try {
    const data = await request.get('/api/common/cos/token?type=' + type);
    console.log('%c [ data ]', 'font-size:13px; background:pink; color:#bf2c9f;', data);
    return responseHelper(data).data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const fetchCosSignTokenWithCallback = (type, callback = () => ({})) => {
  return fetchCosSignToken(type).then(data => {
    const credentials = data && data.credentials;

    if (!data || !credentials) {
      _Message.error('上传token');

      return console.error('credentials invalid');
    }

    return callback({
      TmpSecretId: credentials.tmpSecretId,
      TmpSecretKey: credentials.tmpSecretKey,
      XCosSecurityToken: credentials.sessionToken,
      // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
      StartTime: data.startTime,
      // 时间戳，单位秒，如：1580000000
      ExpiredTime: data.expiredTime // 时间戳，单位秒，如：1580000900

    });
  }).finally(() => {
    console.log('获取token完成');
  });
};