import { message } from 'antd';
export const compose = (...funcs) => funcs.reduce((acc, func) => (...args) => acc(func(...args)));
export const pipeline = (...funcs) => funcs.reduce((acc, func) => (...args) => func(acc(...args)));
export const ifFlow = (...conditionActions) => (...args) => {
  // for (const [condtion, action] of conditionActions) {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < conditionActions.length; ++i) {
    const [condition, action] = conditionActions[i];
    if (condition(...args)) return action(...args);
  }

  return null;
};
export const flow = (...funcs) => funcs.reduce((acc, func) => (next, ...args) => acc(() => func(next, ...args), ...args));
export const id = x => x;
export const Condition = {
  or: (...conditionFuncs) => conditionFuncs.reduce((acc, func) => (...args) => acc(...args) || func(...args), () => false),
  and: (...conditionFuncs) => conditionFuncs.reduce((acc, func) => (...args) => acc(...args) && func(...args), () => true)
};
export const feedback = (func, successHandler, errorHandler) => {
  const onSuccess = typeof successHandler === 'function' ? successHandler : () => message.success(successHandler || '操作成功');
  const onError = typeof errorHandler === 'function' ? errorHandler : e => {
    console.error(e);
    message.error(errorHandler || '操作失败');
    return true;
  };

  try {
    const result = func();

    if (typeof result?.then === 'function') {
      result.then(onSuccess).catch(onError);
    } else {
      onSuccess(result);
    }

    return result;
  } catch (e) {
    onError(e);
  }
};
export const feedbackF = (func, successHandler, errorHandler) => async (...args) => {
  try {
    await feedback(() => func(...args), successHandler, errorHandler);
  } catch {}
};
export const noop = () => null;
export const wait = delay => new Promise(res => setTimeout(res, delay));
export const maybeThrow = fn => {
  let ans = {};

  try {
    ans = fn();
  } catch (e) {
    console.error(e);
  } finally {
    return ans;
  }
};
/**
 * @example
 *  const frac1 = (f)=>n=>n>1?n*f(n-1):1;
 *  const frac = Y(frac1);
 * @see https://en.wikipedia.org/wiki/Fixed-point_combinator
 * @param {function} f1 递归函数主体定义
 * @returns {function}
 */

export const Y = f1 => {
  const helper = f => f1((...args) => f(f)(...args));

  return helper(helper);
  /*
  (f=>f1(f(f)))(f=>f1(f(f)))
  (x=>f1(x(x)))(y=>f1(y(y)))
  f1((y=>f1(y(y)))(y=>f1(y(y))))
  f1(Y(f1))
  */
}; // const handler = f=>(n,i=0)=> i < n? i+f(n,i+1):n;
// const fib = Y(fib1);
// const Y = (func) => func(Y(func));
// const Y = (func) => {
//   const helper = (f) => func((...args) => f(f)(...args));
//   return helper(helper);
// };
// const handler = (func) => (n, next = id) => (n > 1 ? next(func(n - 1)) : next(n));
// Y(handler)(2);
// const Y = (func) => {
//   const helper = (f) => func((...args) => f(f)(...args));
//   return helper(helper);
// };
// const fib = f=>(i,next = x=>x)=>{
//   if(i>2) return f(i-1,x1=>f(i-2,x2=>next(x2+x1)));
//   return next(i);
// }
// Y(fib)(5) // 8