const SUCCESS_STATUS_CODE = [200, 201, '200', '201'];
export const responseHelper = response => {
  return new Proxy(response, {
    get(target, attribute) {
      console.log(`%c  URL: ${target.config.url} \n  [ Authorization : ${target.config?.headers?.Authorization} ]`, 'font-size:15px; background:#000; color:#229f85;');
      const {
        data
      } = target;
      console.log(`%c [ request->url -> ${target.config.url}  ] => \n `, 'font-size:16px; background:#000; color:#229f85;', data);

      if (attribute === 'list') {
        if (SUCCESS_STATUS_CODE.includes(target.status)) {
          return {
            success: true,
            page: data?.current_page || data?.page || data?.current || 1,
            size: data?.per_page || data?.size || data?.pageSize || 15,
            data: data?.data || data?.list || data || [],
            total: data?.total || data?.length || 0
          };
        } else {
          return {
            success: false,
            data: [],
            total: 0
          };
        }
      }

      if (attribute === 'create' || attribute === 'update') {
        if (SUCCESS_STATUS_CODE.includes(target.status)) {
          return target.data;
        } else {
          return {};
        }
      }

      if (attribute === 'destroy') {
        if (SUCCESS_STATUS_CODE.includes(target.status)) {
          return target.data;
        } else {
          return {};
        }
      }

      return target[attribute];
    }

  });
};