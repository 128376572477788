import { V } from '@/lib/utils/constLazyValue';
import { ObjectFieldItems, ArrayFieldItems, DependFieldItems, GroupFieldItems } from './components.js';
/**
 * @typedef {import('./interface').CommonItem CommonItem}
 */

/**
 * @type {CommonItem}
 */

export const baseType = V.rules([]).required(false).props(undefined).initialValue(undefined).tableShow(false); // const tryGetValue = (v) => (isConstLazyValue(v) ? getValue(v) : v);

/**
 * @template T 值信息
 *
 * @param {CommonItem<T>} itemType 描述数组单元信息
 * @returns {CommonItem}
 */

export const array = itemType => baseType.type('array').child(itemType).render(ArrayFieldItems);
/**
 *
 * @param {Record<string,CommonItem<any>>} descriptor 描述对象
 * @returns {CommonItem}
 */

export const object = descriptor => baseType.type('object').children(descriptor).render(ObjectFieldItems).isGraphqlJSON(true);
/**
 *
 * @param {Record<string,CommonItem<any>>} descriptor 描述对象
 * @returns {CommonItem}
 */

export const objectCol = descriptor => baseType.type('object').children(descriptor).render(ObjectFieldItems).props({
  noGroup: true
}).isGraphqlJSON(true);
/**
 * @template T {T extends any[]}值的Union
 *
 * @param {CommonItem<T>} enumType 枚举类型的CommonItem
 * @returns {CommonItem}
 */

export const enumMuti = enumType => baseType.type('enumGroup').enumType(enumType);
/**
 *
 * @param  {...CommonItem<object>} units CommonItem类型的数组
 * @returns {CommonItem}
 */

export const group = (...units) => baseType.type('group').groupUnits(units).render(GroupFieldItems);
/**
 * @template T
 *
 * @param {string[]} depended 依赖的数组
 * @param {CommonItem<T>} dependency 描述依赖项的CommonItem
 * @returns {CommonItem}
 */

export const depend = (depended, dependency) => baseType.type('dependency').dependency(dependency).depended(depended).render(DependFieldItems); // const parseAtom = ({ rules, initialValue, props, render, type, label, ...others }) => {
//   return {
//     rules: [{ ...others, type }].concat(rules),
//     initialValue,
//     props: { ...props, label },
//     render,
//   };
// };
// const parseArray = ({ child, rules, initialValue, props, render, ...others }) => {
//   const {
//     rules: childRules,
//     initialValue: childDefaultValue,
//     props: childProps,
//     render: childRender,
//   } = parse(child);
//   return {
//     rules: rules.concat([
//       // { defaultField: childRules.reduce((acc, r) => ({ ...acc, ...r }), {}), ...others },
//       { defaultField: childRules[0], ...others },
//     ]),
//     initialValue: initialValue || [childDefaultValue],
//     props: { ...props, childProps, childRender },
//     render,
//   };
// };
// const parseObject = ({ children, rules, initialValue, props, render, ...others }) => {
//   const entries = Object.entries(tryGetValue(children)).map(([name, value]) => [
//     name,
//     parse(value),
//   ]);
//   const childrenFields = entries.reduce(
//     (acc, [name, { rules }]) => ({
//       ...acc,
//       [name]: rules,
//     }),
//     {},
//   );
//   const childrenDefaultValue = entries.reduce(
//     (acc, [name, { initialValue }]) => ({
//       ...acc,
//       [name]: initialValue,
//     }),
//     {},
//   );
//   return {
//     rules: rules.concat({ fields: childrenFields, ...others }),
//     initialValue: initialValue || childrenDefaultValue,
//     props: {
//       ...props,
//       childrenEntries: entries,
//     },
//     render,
//   };
// };
// const convertValueEnum = (valueEnum) => {
//   if (Array.isArray(valueEnum)) {
//     if (typeof valueEnum[0] === 'string') return [valueEnum, { options: valueEnum }];
//     return [valueEnum.map(({ value }) => value), { options: valueEnum }];
//   }
//   return [Object.keys(valueEnum), { valueEnum }];
// };
// const parseEnum = ({
//   rules,
//   initialValue,
//   props,
//   render,
//   type,
//   valueEnum,
//   required,
//   label,
//   ...others
// }) => {
//   if (valueEnum) {
//     const [enumV, toEnumGroup] = convertValueEnum(valueEnum);
//     return {
//       rules: [{ ...others, type, enum: enumV }].concat(rules),
//       initialValue,
//       props: { ...props, ...toEnumGroup, label },
//       render,
//       toEnumGroup,
//     };
//   }
//   return {
//     rules: [{ required: !!required }].concat(rules),
//     initialValue,
//     props: { ...props, label: label || props.label },
//     render,
//   };
// };
// const parseEnumGroup = ({ enumType, rules, initialValue, props, render, label, ...others }) => {
//   const { toEnumGroup, ...enumObj } = parse(enumType);
//   return {
//     rules: [
//       {
//         defaultField: enumObj.rules.reduce((acc, r) => ({ ...acc, ...r }), {}),
//         ...others,
//         type: 'array',
//       },
//     ].concat(rules),
//     render,
//     initialValue: initialValue || (enumObj.initialValue ? [enumObj.initialValue] : []),
//     props: { ...props, ...toEnumGroup, enumType: enumObj, label: label || props.label },
//   };
// };
// export const parse = (value) => {
//   try {
//     const v = tryGetValue(value);
//     if (v.type === 'object') return parseObject(v);
//     if (v.type === 'array') return parseArray(v);
//     if (v.type === 'enumGroup') return parseEnumGroup(v);
//     if (v.type === 'enum') return parseEnum(v);
//     return parseAtom(v);
//   } catch (e) {
//     // eslint-disable-next-line no-console
//     console.error(e);
//   }
// };