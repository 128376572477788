import { createParser, getValue, isConstLazyValue } from '@/lib/utils/constLazyValue';
import baseParser from './base';
import initialValueParser from './initialValue';
import propsParser from './props';
import rulesParser from './rules';
import excelParser from './excel';
import graphQlParser from './graphql';
import tableColumnParser from './tableColumn';

const tryGetValue = v => isConstLazyValue(v) ? getValue(v) : v;

export const labelParse = ([next], obj) => {
  if (obj.label) {
    const {
      props,
      label,
      ...others
    } = obj;
    return next({ ...others,
      label,
      props: { ...props,
        label
      }
    });
  }

  return next(obj);
}; // const parseAtom = ({ rules, initialValue, props, render, type, ...others }) => {
//   return {
//     rules: [{ ...others, type }].concat(rules),
//     initialValue,
//     props,
//     render,
//   };
// };
// const parseArray = ({ childProps, rules, initialValue, props, render, ...others }) => {
//   return {
//     // rules: rules.concat([
//     //   { defaultField: childProps.rules.reduce((acc, r) => ({ ...acc, ...r }), {}), ...others },
//     //   // { defaultField: childProps.rules[0], ...others },
//     // ]),
//     rules: rules.concat([
//       { defaultField: childProps.rules.reduce((acc, r) => ({ ...acc, ...r }), {}), ...others },
//     ]),
//     initialValue,
//     props,
//     render,
//   };
// };
// const parseObject = ({ childrenEntries, rules, initialValue, props, render, ...others }) => {
//   const childrenFields = childrenEntries.reduce(
//     (acc, [name, { rules }]) => ({
//       ...acc,
//       [name]: rules,
//     }),
//     {},
//   );
//   return {
//     rules: rules.concat({ fields: childrenFields, ...others }),
//     initialValue,
//     props,
//     render,
//   };
// };
// const convertValueEnum = (valueEnum) => {
//   if (Array.isArray(valueEnum)) {
//     if (typeof valueEnum[0] === 'string') return [valueEnum, { options: valueEnum }];
//     return [valueEnum.map(({ value }) => value), { options: valueEnum }];
//   }
//   return [Object.keys(valueEnum), { valueEnum }];
// };
// const parseEnum = ({
//   rules,
//   initialValue,
//   props,
//   render,
//   type,
//   valueEnum,
//   required,
//   ...others
// }) => {
//   if (valueEnum) {
//     const [enumV] = convertValueEnum(valueEnum);
//     return {
//       rules: [{ ...others, type, enum: enumV, required }].concat(rules),
//       initialValue,
//       props,
//       render,
//     };
//   }
//   return {
//     rules: [{ required: !!required }].concat(rules),
//     initialValue,
//     props,
//     render,
//   };
// };
// const parseEnumGroup = ({ enumType, rules, initialValue, props, render, ...others }) => {
//   return {
//     rules: [
//       {
//         defaultField: enumType.rules.reduce((acc, r) => ({ ...acc, ...r }), {}),
//         ...others,
//         type: 'array',
//       },
//     ].concat(rules),
//     render,
//     initialValue,
//     props,
//   };
// };
// export const formItemParse_old = ([next], obj) => {
//   const v = next(obj);
//   if (v.type === 'object') return parseObject(v);
//   if (v.type === 'array') return parseArray(v);
//   if (v.type === 'enumGroup') return parseEnumGroup(v);
//   if (v.type === 'enum') return parseEnum(v);
//   return parseAtom(v);
// };
// export const formItemParse = (v, getScopedValue = id) => {
//   if (v.type === 'object') return parseObject(v, getScopedValue);
//   if (v.type === 'array') return parseArray(v, getScopedValue);
//   if (v.type === 'enumGroup') return parseEnumGroup(v, getScopedValue);
//   if (v.type === 'enum') return parseEnum(v, getScopedValue);
//   return typeof v.type === 'string' ? parseAtom(v, getScopedValue) : v;
// };

const NullComp = () => null;

export const parse = createParser(([next], v) => {
  return next(tryGetValue(v));
}, //新增
([next], v) => typeof v.type === 'string' ? next(v) : v, ([next], v) => {
  // console.log(
  //   '%c [ v ]',
  //   'font-size:13px; background:pink; color:#bf2c9f;',
  //   v,
  // );
  if (v.initialValue === '' || !v.initialMode) {
    v.initialValue = undefined;
  }

  return next(v);
}, // props
propsParser.parseArray, propsParser.parseObject, propsParser.parseEnum, propsParser.parseGroup, propsParser.parseEnumGroup, propsParser.parseDependency, // rules
rulesParser.parseArray, rulesParser.parseObject, rulesParser.parseGroup, rulesParser.parseEnumGroup, rulesParser.parseEnum, rulesParser.parseAtom, rulesParser.parseDependency, excelParser.parseObject, excelParser.parseAtom, excelParser.parseDate, excelParser.parseEnum, // initialValue
initialValueParser.parseObject, initialValueParser.parseGroup, initialValueParser.parseArray, initialValueParser.parseEnumGroup, initialValueParser.parseDependency, // getGraphql
// graphQlParser.parseGroup,
graphQlParser.parseObject, graphQlParser.parseAtom, graphQlParser.parseDate, graphQlParser.parseEnum, // tableColumn
tableColumnParser.parseObject, tableColumnParser.parseGroup, tableColumnParser.parseAtom, tableColumnParser.parseEnum, // base
baseParser.parseObject, baseParser.parseGroup, baseParser.parseArray, baseParser.parseEnumGroup, baseParser.parseDependency, //
labelParse, // bindLayerSkip(Array)=>bindLayerSkit(Map)
([next], v) => {
  const {
    bindLayerSkip,
    render,
    ...obj
  } = next(v);
  const tmp = bindLayerSkip?.reduce?.((acc, v) => ({ ...acc,
    [v]: true
  }), {});
  return { ...obj,
    ...(bindLayerSkip && {
      bindLayerSkip: tmp
    }),
    render: tmp?.formItem ? NullComp : render
  };
});
export const parseWithEnv = ({
  mode = 'create'
} = {}) => createParser(([next], v) => {
  return next(tryGetValue(v));
}, //新增
([next], v) => typeof v.type === 'string' ? next(v) : v, ([next], v) => {
  // console.log(
  //   '%c [ v ]',
  //   'font-size:13px; background:pink; color:#bf2c9f;',
  //   v,
  // );
  // console.log('[ mode ] >', mode);
  if (typeof v.initialMode === 'string' && v.initialMode === mode || v.initialMode?.includes(mode)) {
    console.log(`${mode} ${v.label} 保留 初始值`, v.initialValue, v.initialMode, v.props?.initialValue);
  } else if (v.initialValue === '' || mode === 'edit') {
    v.initialValue = undefined;

    if (v.props?.initialValue) {
      console.log('[ YES  ] >', v.props?.initialValue);
      v.props.initialValue = undefined;
    }
  }

  return next(v);
}, // props
propsParser.parseArray, propsParser.parseObject, propsParser.parseEnum, propsParser.parseGroup, propsParser.parseEnumGroup, propsParser.parseDependency, // rules
rulesParser.parseArray, rulesParser.parseObject, rulesParser.parseGroup, rulesParser.parseEnumGroup, rulesParser.parseEnum, rulesParser.parseAtom, rulesParser.parseDependency, excelParser.parseObject, excelParser.parseAtom, excelParser.parseDate, excelParser.parseEnum, // initialValue
initialValueParser.parseObject, initialValueParser.parseGroup, initialValueParser.parseArray, initialValueParser.parseEnumGroup, initialValueParser.parseDependency, // getGraphql
// graphQlParser.parseGroup,
graphQlParser.parseObject, graphQlParser.parseAtom, graphQlParser.parseDate, graphQlParser.parseEnum, // tableColumn
tableColumnParser.parseObject, tableColumnParser.parseGroup, tableColumnParser.parseAtom, tableColumnParser.parseEnum, // base
baseParser.parseObjectWithEnv({
  mode
}), baseParser.parseGroup, baseParser.parseArray, baseParser.parseEnumGroup, baseParser.parseDependency, //
labelParse, // bindLayerSkip(Array)=>bindLayerSkit(Map)
([next], v) => {
  const {
    bindLayerSkip,
    render,
    ...obj
  } = next(v);
  const tmp = bindLayerSkip?.reduce?.((acc, v) => ({ ...acc,
    [v]: true
  }), {});
  return { ...obj,
    ...(bindLayerSkip && {
      bindLayerSkip: tmp
    }),
    render: tmp?.formItem ? NullComp : render
  };
});