import { pick } from 'ramda';
import { targetHelper } from './utils';
const rulesPick = pick(['type', 'required', 'pattern', 'range', 'length', 'whitespace', 'transform', 'message', 'asyncValidator', 'validator']);
const parseArray = targetHelper('array', ([next], v) => {
  const {
    rules,
    ...others
  } = next(v);
  return { ...others,
    rules: [{
      defaultField: others.childProps.rules.reduce((acc, r) => ({ ...acc,
        ...r
      }), rulesPick(others)),
      // type: others.type,
      ...others
    }].concat(rules || [])
  };
});
const parseObject = targetHelper('object', ([next], v) => {
  const {
    rules,
    ...others
  } = next(v);
  const childrenFields = others.childrenEntries.reduce((acc, [name, {
    rules
  }]) => ({ ...acc,
    [name]: rules
  }), {});
  return { ...others,
    rules: [{
      fields: childrenFields,
      ...rulesPick(others)
    }].concat(rules || [])
  };
});
export const parseObjectWith = targetHelper('object', ([next], v) => {
  const {
    rules,
    ...others
  } = next(v);
  const childrenFields = others.childrenEntries.reduce((acc, [name, {
    rules
  }]) => ({ ...acc,
    [name]: rules
  }), {});
  return { ...others,
    rules: [{
      fields: childrenFields,
      ...rulesPick(others)
    }].concat(rules || [])
  };
});

const convertValueEnum = valueEnum => {
  if (Array.isArray(valueEnum)) {
    if (typeof valueEnum[0] === 'string') return [valueEnum, {
      options: valueEnum
    }];
    return [valueEnum.map(({
      value
    }) => value), {
      options: valueEnum
    }];
  }

  return [Object.keys(valueEnum), {
    valueEnum
  }];
};

const parseEnum = targetHelper('enum', ([next], v) => {
  const {
    rules,
    ...others
  } = next(v);

  if (others.valueEnum) {
    const [enumV] = convertValueEnum(others.valueEnum);
    return { ...others,
      rules: [{
        enum: enumV,
        ...rulesPick(others)
      }].concat(rules || [])
    };
  } // return { ...others, rules: [{ ...rulesPick(others) }].concat(rules || []) };


  return { ...others,
    rules
  };
});
const parseDependency = targetHelper('dependency', ([next], v) => {
  const { ...others
  } = next(v);
  return { ...others,
    rules: others.dependency.rules
  };
});
const parseEnumGroup = targetHelper('enumGroup', ([next], v) => {
  const {
    rules,
    ...others
  } = next(v);
  return { ...others,
    rules: [{
      defaultField: others.enumType.rules.reduce((acc, r) => ({ ...acc,
        ...r
      }), {}),
      ...rulesPick(others),
      type: 'array'
    }].concat(rules)
  };
});

const parseAtom = ([next], v) => {
  const {
    rules,
    ...others
  } = next(v);
  if (/enum|enumGroup|object|array|dependency/.test(v.type)) return {
    rules,
    ...others
  };
  return { ...others,
    rules: [rulesPick(others)].concat(rules || [])
  };
};

const parseGroup = targetHelper('group', ([next], v) => {
  const {
    rules,
    ...others
  } = next(v);
  const unitFields = others.groupUnits;
  return { ...others,
    rules: (rules || []).concat(unitFields.map(({
      rules
    }) => rules))
  };
});
export default {
  parseObject,
  parseArray,
  parseEnum,
  parseEnumGroup,
  parseGroup,
  parseAtom,
  parseDependency
};