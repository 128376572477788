import "@arco-design/web-react/es/Message/style";
import _Message from "@arco-design/web-react/es/Message";
import axios from 'axios';
import { API_SERVER_HOST } from '../env'; // import { history } from 'umi';

import { getAuthorizationToken, setAuthorizationToken } from '../env';
export const request = axios.create({
  baseURL: API_SERVER_HOST
});
request.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
request.defaults.withCredentials = true; //是否带上Token

export const withAuthorization = config => {
  let token = getAuthorizationToken();

  if (token) {
    token = token.replaceAll('"', '');
    config.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return config;
}; // 添加请求拦截器

export const useInterceptor = fn => request.interceptors.request.use(fn); // 移除请求拦截器

export const removeInterceptor = fn => request.interceptors.request.eject(fn); // 根据token自动添加或移除拦截器

request.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response) {
    console.log('%c [ error.response ]', 'font-size:13px; background:pink; color:#bf2c9f;', error.response);
    let response;

    switch (error.response.status) {
      case 401:
        _Message.log('登录状态获取失败');

        history.push('/logout'); // history.push('/logout'); //直接退出登陆

        break;

      case 422:
        response = error.response;
        const {
          data: {
            errors
          } = {}
        } = response;
        let msg = Object.keys(errors).reduce((pre, cur) => {
          return pre + errors[cur] + ',';
        }, '');

        if (msg.length) {
          msg = msg.slice(0, -1);
        }

        _Message.error('出错了:请求错误\n' + msg, 50);

        break;

      case 403:
        response = error.response;
        const {
          data: {
            message: msgError
          }
        } = response;

        _Message.error('出错了:请求错误\n' + msgError, 50);

        break;

      case 500:
        response = error.response;
        const {
          data: {
            message: es
          }
        } = response;

        _Message.error('出错了:请求错误\n' + es, 50);

        break;

      default:
        _Message.log(error.response);

    }
  }

  _Message.error('http->error', error);

  return Promise.reject(error); // 返回接口返回的错误信息
});
export const reInjectAuthorization = token => {
  if (!token) {
    removeInterceptor(withAuthorization);
    setAuthorizationToken(); //
  } else {
    removeInterceptor(withAuthorization);
    setAuthorizationToken(token); //
    // eslint-disable-next-line react-hooks/rules-of-hooks

    useInterceptor(withAuthorization);
  }
};