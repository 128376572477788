import { targetHelperBackend } from './utils';
const parseObject = targetHelperBackend('object', (_, obj) => {
  return { ...obj,
    getGraphql: (type = 'list', isRoot = false) => name => {
      const gqls = () => obj.childrenEntries.map(([name, {
        getGraphql
      }]) => getGraphql?.(type)?.(name) || name);

      if (type === 'list') {
        return !obj.isGraphqlJSON ? `${name || ''}{${gqls().join(',')}}` : name;
      }

      if (type === 'field' && !isRoot) {
        // const
        return !obj.isGraphqlJSON ? obj.required ? `@Field(()=>'确定Entity')\n@OneToOne('注意修改关联关系',{comment:'${obj.label}'})\n${name}: 'Entity类型';` : `@Field(()=>'确定Entity',{nullable:true})\n@OneToOne('注意修改关联关系',{nullable:true,comment:'${obj.label}'})\n${name}?: 'Entity类型';` : obj.required ? `@Field(()=>GraphQLJSON)\n@Column({type:'json',comment:'${obj.label}'})\n${name}: any;` : `@Field(()=>GraphQLJSON,{nullable:true})\n@Column({type:'json',nullable:true,comment:'${obj.label}'})\n${name}: any;`;
      }

      if (type === 'field' && isRoot) {
        return [`@Field(() => ID)\n@PrimaryGeneratedColumn()\nid: number;`, ...gqls()].join('\n\n');
      }

      if (type === 'baseInput' && !isRoot) {
        // const
        return !obj.isGraphqlJSON ? `@Field(()=>'确定Entity',{nullable:true})\n${name}?: 'Entity类型';` : `@Field(()=>GraphQLJSON,{nullable:true})\n${name}: any;`;
      }

      if (type === 'baseInput' && isRoot) {
        return gqls().join('\n\n');
      }
    }
  };
});
const supportTypeReg = /(object)|(array)|(enumGroup)|(enum)|(date)/;
const parseAtom = targetHelperBackend(type => !supportTypeReg.test(type), (_, obj) => {
  return { ...obj,
    getGraphql: (type = 'list') => name => {
      if (obj.getGraphql) return obj.getGraphql(type)?.(name); // for dependency

      if (type === 'field') return obj.required ? `@Field()\n@Column({comment:'${obj.label}'})\n${name}: ${obj.type};` : `@Field({nullable:true})\n@Column({nullable:true,comment:'${obj.label}'})\n${name}?: ${obj.type};`;
      if (type === 'baseInput') return `@Field({nullable:true})\n${name}?: ${obj.type};`;
    }
  };
});
const parseEnum = targetHelperBackend('enum', (_, obj) => {
  return { ...obj,
    getGraphql: (type = 'list') => name => {
      if (obj.getGraphql) return obj.getGraphql(type)?.(name); // for dependency

      const valueEnum = Array.isArray(obj.valueEnum) ? obj.valueEnum : [{
        value: '请查阅数据字典'
      }];
      const valueUnion = valueEnum.map(({
        value
      }) => `'${value}'`).join('|'); // console.log('valueEnum', valueEnum);

      if (type === 'field') return obj.required ? `@Field(()=>String)\n@Column({type:'varchar',comment:'${obj.label}'})\n${name}: ${valueUnion};` : `@Field(()=>String,{nullable:true})\n@Column({type:'varchar',nullable:true,comment:'${obj.label}'})\n${name}?: ${valueUnion};`;
      if (type === 'baseInput') return `@Field(()=>String,{nullable:true})\n${name}?: ${valueUnion};`;
    }
  };
});
const parseDate = targetHelperBackend('date', (_, obj) => {
  return { ...obj,
    getGraphql: (type = 'list') => name => {
      if (obj.getGraphql) return obj.getGraphql(type)?.(name); // for dependency

      if (type === 'field') return obj.required ? `@Field(()=>Date)\n@Column({type:'timestamp',comment:'${obj.label}'})\n${name}: Date;` : `@Field(()=>Date,{nullable:true})\n@Column({type:'timestamp',nullable:true,comment:'${obj.label}'})\n${name}?: Date;`;
      if (type === 'baseInput') return `@Field(()=>Date,{nullable:true})\n${name}?: Date;`; // return name;
    }
  };
}); // const parseGroup = targetHelper('group', ([next], v) => {
//   const obj = next(v);
//   return {
//     ...obj,
//     getGraphql: (name) => {
//       const tmps = [].concat(...obj.groupUnits.map(({ childrenEntries }) => childrenEntries));
//       const gqls = tmps.map(([name, { getGraphql }]) =>
//         getGraphql ? getGraphql(name) : { list: name },
//       );
//       console.log(gqls);
//       return {
//         list: `${name || ''}{${gqls.map(({ list }) => list).join(',')}}`,
//         // list: `${name || ''}{${gqls.map(({ list }) => list).join(',')}}`,
//       };
//     },
//   };
// });

export default {
  // parseGroup,
  parseObject,
  parseAtom,
  parseDate,
  parseEnum
};