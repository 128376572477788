// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useState, useEffect } from 'react';
import createStore from 'zustand';
import { persist } from 'zustand/middleware'; //COS相关代码

import * as COS from 'cos-js-sdk-v5';
import { getCosToken } from '@/service/cos';
const tokenStore = {};
export const useCosStore = createStore(persist(set => ({
  cosToken: null,
  setCosToken: cosToken => {
    set({
      cosToken: cosToken
    });
  }
}), {
  name: "cos_token_store"
})); //使用腾讯云 存储cos

export const useCosController = (type, data, forceNew = false) => {
  if (!forceNew && tokenStore[type]) {
    return tokenStore[type];
  }

  tokenStore[type] = new COS({
    // 必选参数
    getAuthorization: function (options, callback) {
      // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
      // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
      // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
      const transform = ({
        credentials,
        startTime,
        expiredTime
      } = data) => {
        return {
          TmpSecretId: credentials.tmpSecretId,
          TmpSecretKey: credentials.tmpSecretKey,
          XCosSecurityToken: credentials.sessionToken,
          StartTime: startTime,
          // 时间戳，单位秒，如：1580000000，建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
          ExpiredTime: expiredTime // 时间戳，单位秒，如：1580000900

        };
      };

      callback(transform(data));
    }
  });
  return tokenStore[type];
};

const getCosService = async (type = 'cunan') => {
  const preData = useCosStore.getState().cosToken;
  console.log('%c [ preData ]-45', 'font-size:13px; background:pink; color:#bf2c9f;', preData);

  if (preData && preData.expiredTime > Date.now() / 1000 + 600) {
    console.log('%c [ COS 尚未过期，本次不再发送请求 ]', 'font-size:13px; background:#245532; color:#fff;'); //如果上一个token还有超过10分钟以上持续时间，依然使用上一个token
    // eslint-disable-next-line react-hooks/rules-of-hooks

    return useCosController(type, preData);
  } else {
    console.log('%c [ COS 已过期，发送请求 ]', 'font-size:13px; background:#245532; color:#fff;'); //如果上一个token过期了，发送请求获取新的token

    const data = await getCosToken(type);
    useCosStore.setState({
      cosToken: data
    }); // eslint-disable-next-line react-hooks/rules-of-hooks

    return useCosController(type, data, true);
  }
};

let singleReq = null;
export const useCos = type => {
  const [cos, setCos] = useState();
  const [loading, setLoading] = useState();
  useEffect(() => {
    setLoading(true);

    if (!singleReq) {
      singleReq = getCosService(type);
    }

    singleReq.then(data => {
      console.log('%c [ setCos ]-73', 'font-size:13px; background:red; color:#fff;', data);
      setCos(data);
      setLoading(false);
    });
  }, [type]);
  return {
    loading,
    cos
  };
};
export default useCos;