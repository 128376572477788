import { targetHelper } from './utils'; // export const arrayParse = ([next, reParse], obj) => {
//   if (obj.type === 'array') {
//     const { child, ...others } = obj;
//     return {
//       ...next(others),
//       childProps: reParse(child),
//     };
//   }
//   return next(obj);
// };
// export const enumGroupParse = ([next, reParse], obj) => {
//   if (obj.type === 'enumGroup') {
//     const { enumType, ...others } = obj;
//     return {
//       ...next(others),
//       enumType: reParse(enumType), // 这里可以约束enumType一定可解析为enum
//     };
//   }
//   return next(obj);
// };
// export const objectParse = ([next, reParse], obj) => {
//   if (obj.type === 'object') {
//     const { children: _children, ...others } = obj;
//     const children = reParse(_children);
//     return {
//       ...next(others),
//       childrenEntries: Object.entries(children).map(([name, v]) => [name, reParse(v)]),
//     };
//   }
//   return next(obj);
// };

const parseObject = targetHelper('object', ([next, reParse], v) => {
  const {
    children,
    ...others
  } = v;
  return { ...next(others),
    childrenEntries: Object.entries(reParse(children)).map(([name, child]) => [name, reParse(child)])
  };
});

const parseObjectWithEnv = ({
  mode
} = {}) => targetHelper('object', ([next, reParse], v) => {
  const {
    children,
    ...others
  } = v;
  return { ...next(others),
    childrenEntries: Object.entries(reParse(children)).map(([name, child]) => [name, reParse(child)]).filter(([, child]) => {
      return !child.hideInMode?.includes(mode);
    })
  };
});

const parseArray = targetHelper('array', ([next, reParse], v) => {
  const {
    child,
    ...others
  } = v;
  return { ...next(others),
    childProps: reParse(child)
  };
});
const parseEnumGroup = targetHelper('enumGroup', ([next, reParse], v) => {
  const {
    enumType,
    ...others
  } = v;
  return { ...next(others),
    enumType: reParse(enumType)
  };
});
const parseDependency = targetHelper('dependency', ([next, reParse], v) => {
  const {
    dependency,
    ...others
  } = v;
  const tmp = reParse(dependency);
  const n = next(others);
  return { ...n,
    ...tmp,
    render: n.render,
    dependency: tmp,
    props: { ...tmp.props,
      ...n.props
    }
  };
});
const parseGroup = targetHelper('group', ([next, reParse], v) => {
  const {
    groupUnits,
    ...others
  } = v;
  const tmp = groupUnits.map(v => reParse(v));
  const hsj = [].concat(...tmp.map(t => t.childrenEntries));
  console.log(hsj);
  return { ...next(others),
    type: 'object',
    groupUnits: tmp,
    childrenEntries: Object.entries(hsj.reduce((acc, [n, v]) => ({ ...acc,
      [n]: v
    }), {}))
  };
});
export default {
  parseObject,
  parseObjectWithEnv,
  parseArray,
  parseEnumGroup,
  parseDependency,
  parseGroup
};