import { pick } from 'ramda';
import { targetHelper, targetHelperBackend } from './utils';
const parseArray = targetHelper('array', ([next], v) => {
  const {
    props,
    ...others
  } = next(v);
  return { ...others,
    props: { ...props,
      childProps: others.childProps
    }
  };
});
const parseObject = targetHelperBackend('object', (_, v) => {
  const {
    props,
    ...others
  } = v;
  return { ...others,
    props: { ...props,
      childrenEntries: others.childrenEntries
    }
  };
});
const parseGroup = targetHelper('group', ([next], v) => {
  const {
    props,
    ...others
  } = next(v);
  return { ...others,
    props: { ...props,
      groupUnits: others.groupUnits
    }
  };
});

const convertValueEnum = valueEnum => {
  if (Array.isArray(valueEnum)) {
    if (typeof valueEnum[0] === 'string') return [valueEnum, {
      options: valueEnum
    }];
    return [valueEnum.map(({
      value
    }) => value), {
      options: valueEnum
    }];
  }

  return [Object.keys(valueEnum), {
    valueEnum
  }];
};

const parseEnum = targetHelper('enum', ([next], v) => {
  const {
    props,
    ...others
  } = next(v);

  if (others.valueEnum) {
    const toEnumGroup = convertValueEnum(others.valueEnum)[1];
    return { ...others,
      props: { ...props,
        ...toEnumGroup
      }
    };
  }

  return { ...others,
    props
  };
});
const parseEnumGroup = targetHelper('enumGroup', ([next], v) => {
  const {
    props,
    ...others
  } = next(v);
  return { ...others,
    props: { ...props,
      ...pick(['valueEnum', 'options'], others.enumType.props),
      enumType: others.enumType
    }
  };
});
const parseDependency = targetHelper('dependency', ([next], v) => {
  const {
    props,
    customProps,
    ...others
  } = next(v); // if (props) console.warn('[dependency]:dependency不支持props');

  if (!customProps || typeof customProps !== 'function') console.log('[dependency]:没有传递customProps或者使用了非函数类型的customProps');
  return { ...others,
    props: {
      customProps,
      dependency: others.dependency,
      depended: others.depended,
      ...props
    }
  };
});
export default {
  parseObject,
  parseEnum,
  parseGroup,
  parseEnumGroup,
  parseArray,
  parseDependency
};