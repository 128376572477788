import { responseHelper } from '../helpers/response';
import { fetchCosSignToken } from './auth';
import { request } from './request';
export const getCosToken = async type => {
  const data = await fetchCosSignToken(type);
  return data;
}; //向个人账户的资源中插入一条记录

export const storeUserCosObject = async data => {
  const res = await request({
    method: 'post',
    url: '/api/user/cos',
    data
  });
  return responseHelper(res).data;
}; //向个人账户的资源中插入一条记录

export const destroyUserCosObject = async data => {
  const res = await request({
    method: 'DELETE',
    url: '/api/user/cos',
    data
  });
  return responseHelper(res).data;
};
export const storeCompanyCosObject = async data => {
  const res = await request({
    method: 'post',
    url: '/api/company/cos',
    data
  });
  return responseHelper(res).data;
};
export const destroyCompanyCosObject = async data => {
  const res = await request({
    method: 'DELETE',
    url: '/api/company/cos',
    data
  });
  return responseHelper(res).data;
};